import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: HomeView
  },
  {
    path: '/shangpiao',
    name: 'shangpiao',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/scheme/shangpiao'),
  },
  {
    path: '/shanglian',
    name: 'shanglian',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/scheme/shanglian'),
  },
  {
    path: '/kuajing',
    name: 'kuajing',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/scheme/kuajing'),
  },
  {
    path: '/puhui',
    name: 'puhui',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/scheme/puhui'),
  },
  {
    path: '/consult',
    name: 'consult',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/consult'),
  },
  // 数据科技
  {
    path: '/science',
    name: 'science',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/science'),
  },
  // 关于我们
  {
    path: '/inRegardTo',
    name: 'inRegardTo',
    meta: {
      title: "北京商驿科技有限公司",
    },
    component: () => import('@/views/inRegardTo'),
  },
  // 下载邮储银行APP
  {
    path: '/other',
    name: 'other',
    meta: {
      title: "",
    },
    component: () => import('@/views/other/index'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
