<template>
  <div class="box">
    <el-container class="home-container">
      <!-- :class="{ roll: row == 1 }" -->
      <el-header :class="{ roll: roll }">
        <Header></Header>
      </el-header>
      <el-main>
        <div class="banner-wrap">
          <div class="page-container hidden-xs" style="height: 100%">
            <div
              :class="{
                strategicMaps_title: true,
                animated: true,
                fadeInUp: true,
              }"
            >
              <h1>供应链金融科技解决方案专家</h1>
              <h5>数字金融赋能产业发展</h5>
            </div>
            <img class="show" src="../assets/home/005.png" alt="" />
            <video
              width="100%"
              id="video1"
              autoplay=""
              loop=""
              muted=""
              x-webkit-airplay="true"
              airplay="allow"
              webkit-playsinline="true"
              playsinline="true"
              class="bg-video"
              src="@/assets/vedio/vedio.mp4"
            ></video>
          </div>
        </div>
        <div class="content">
          <div
            class="f2"
            :class="{
              strategicMaps_title: true,
              animated: true,
              fadeInUp: true,
            }"
          >
            <div class="f2_title">科技赋能 提质增效</div>
            <!-- 大屏显示 -->
            <div class="f2_list">
              <div class="f2_content">
                <ul>
                  <li class="li1">
                    <img src="../assets/home/组73.png" alt="" />
                    <span>人工智能</span>
                  </li>
                  <li>
                    <img src="../assets/home/组75.png" alt="" />
                    <span style="left: 55px">区块链</span>
                  </li>
                  <li class="li2">
                    <img src="../assets/home/组80.png" alt="" />
                    <span style="left: 55px">云计算</span>
                  </li>
                  <li class="li3">
                    <img src="../assets/home/组81.png" alt="" />
                    <span style="left: 55px">大数据</span>
                  </li>
                  <li class="li4">
                    <img src="../assets/home/组83.png" alt="" />
                    <span style="left: 55px">物联网</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="f3">
            <div class="f3_title">解决方案</div>
            <div class="f3_ul">
              <ul>
                <li
                  :class="{ active2: cut == 1 }"
                  @click="
                    cut = 1;
                    testShow1 = true;
                    testShow4 = false;
                    testShow2 = false;
                    testShow3 = false;
                  "
                >
                  商票驿
                </li>
                <li
                  :class="{ active2: cut == 2 }"
                  @click="
                    cut = 2;
                    testShow2 = true;
                    testShow1 = false;
                    testShow4 = false;
                    testShow3 = false;
                  "
                >
                  跨境驿
                </li>
                <li
                  :class="{ active2: cut == 3 }"
                  @click="
                    cut = 3;
                    testShow3 = true;
                    testShow1 = false;
                    testShow2 = false;
                    testShow4 = false;
                  "
                >
                  商链驿
                </li>
                <li
                  :class="{ active2: cut == 4 }"
                  @click="
                    cut = 4;
                    testShow4 = true;
                    testShow1 = false;
                    testShow2 = false;
                    testShow3 = false;
                  "
                >
                  普惠驿
                </li>
              </ul>
            </div>
            <div class="f3_content">
              <template v-if="cut == 1">
                <div style="display: flex; align-items: center">
                  <div
                    :class="{
                      strategicMaps_title: true,
                      animated: true,
                      fadeInUp: testShow1,
                    }"
                    class="f3_content_left"
                  >
                    <img src="../assets/home/组40.png" alt="" />
                  </div>
                  <div
                    :class="{
                      strategicMaps_title: true,
                      animated: true,
                      fadeInUp: testShow1,
                    }"
                    class="f3_content_right"
                  >
                    <h3>商票驿</h3>
                    <h5>
                      “互联网+票据融通+智慧运营”
                      科技平台，立足供应链内在价值链条，构建票据信息撮合和融通的智慧生态
                    </h5>
                    <div class="f3_content_right_btn" @click="goShangPiao">
                      了解详情
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="cut == 2">
                <div
                  class="f3_content_left"
                  :class="{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: testShow2,
                  }"
                >
                  <img src="../assets/home/组41.png" alt="" />
                </div>
                <div
                  class="f3_content_right"
                  :class="{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: testShow2,
                  }"
                >
                  <h3>跨境驿</h3>
                  <h5>
                    跨境贸易场景企业服务综合平台，打造跨境贸易流通的综合服务生态
                  </h5>
                  <div class="f3_content_right_btn" @click="goKuaJing">
                    了解详情
                  </div>
                </div>
              </template>
              <template v-if="cut == 3">
                <div style="display: flex; align-items: center">
                  <div
                    class="f3_content_left"
                    :class="{
                      strategicMaps_title: true,
                      animated: true,
                      fadeInUp: testShow3,
                    }"
                  >
                    <img src="../assets/home/组42.png" alt="" />
                  </div>
                  <div
                    class="f3_content_right"
                    :class="{
                      strategicMaps_title: true,
                      animated: true,
                      fadeInUp: testShow3,
                    }"
                  >
                    <h3>商链驿</h3>
                    <h5>
                      供应链核心企业数字化转型服务平台，以供应链金融科技平台赋能核心企业资本、资金运营，提高供应链体系运行效率
                    </h5>
                    <div class="f3_content_right_btn" @click="goShangLian">
                      了解详情
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="cut == 4">
                <div
                  class="f3_content_left"
                  :class="{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: testShow4,
                  }"
                >
                  <img src="../assets/home/组43.png" alt="" />
                </div>
                <div
                  class="f3_content_right"
                  :class="{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: testShow4,
                  }"
                >
                  <h3>普惠驿</h3>
                  <h5>
                    产业场景普惠金融创新与运营平台，打造专业的产业场景平台金融服务赋能解决方案
                  </h5>
                  <div class="f3_content_right_btn" @click="goPuHui">
                    了解详情
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="f4">
            <div class="f4_title">业务模式</div>
            <div class="f4_comtent">
              <img src="../assets/home/组38.png" alt="" />
            </div>
          </div>
          <div class="f5">
            <div class="f5_comtent">
              <h3>数智赋能 共创未来</h3>
              <div class="btn" @click="goConsult">留言咨询</div>
            </div>
          </div>
        </div>

        <!-- 二楼 -->

        <Footer></Footer>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
export default {
  components: { Footer, Header },
  data() {
    return {
      cut: 1,
      roll: false,
      testShow1: false,
      testShow2: false,
      testShow3: false,
      testShow4: false,
    };
  },
  mounted() {
    //可以在这里面直接进行滚动条的获取
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll(e) {
      let height = e.target.scrollTop;
      if (height !== 0) {
        this.roll = true;
      } else {
        this.roll = false;
      }
    },
    // 跳转至留言咨询
    goConsult() {
      // 新开窗口显示
      window.open(this.$router.resolve({ path: "/consult" }).href);
      // this.$router.push("/consult");
    },
    // 解决方案
    goKuaJing() {
      if (this.$route.path == "/kuajing") return;
      this.$router.push("/kuajing");
    },
    goShangPiao() {
      if (this.$route.path == "/shangpiao") return;
      this.$router.push("/shangpiao");
    },
    goShangLian() {
      if (this.$route.path == "/shanglian") return;
      this.$router.push("/shanglian");
    },
    goPuHui() {
      if (this.$route.path == "/puhui") return;
      this.$router.push("/puhui");
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  height: 100vh;
  background-color: #f8f9fb;
}
.home-container {
  height: 100vh;
}
// 头部区域
.el-header {
  z-index: 99999;
  width: 100vw;
  position: fixed;
  background-color: rgba(1, 32, 64, 0.5);
  @media only screen and (min-width: 0px) and (max-width: 1050px) {
    background: #041e50;
  }
  z-index: 999;
  height: 80px !important;
  @media only screen and (min-width: 0) and (max-width: 899px) {
    height: 60px !important;
  }
  padding: 0;
  @media only screen and (min-width: 371px) and (max-width: 899px) {
    margin: 0 auto;
  }
  &.roll {
    background: #041e50;
  }
}
// 主体区域
.el-main {
  width: 100vw;
  padding: 0 !important;
  p {
    position: absolute;
    width: 85%;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #b3b3b3;
    text-align: center;
  }
}
.page-container {
  position: relative;
  @media only screen and (min-width: 0) and (max-width: 899px) {
    margin-top: 60px;
  }
  div {
    position: absolute;
    top: 300px;
    left: 360px;
    @media only screen and (min-width: 0px) and (max-width: 399px) {
      width: 100vw;
      text-align: center;
      top: 22%;
      left: 0;
    }
    @media only screen and (min-width: 400px) and (max-width: 799px) {
      width: 100vw;
      text-align: center;
      top: 27%;
      left: 0;
    }
    @media only screen and (min-width: 800px) and (max-width: 899px) {
      top: 40%;
      left: 50px;
    }
    @media only screen and (min-width: 900px) and (max-width: 1250px) {
      top: 40%;
      left: 50px;
    }
    h1 {
      @media only screen and (min-width: 0px) and (max-width: 399px) {
        font-size: 22px;
      }
      @media only screen and (min-width: 400px) and (max-width: 799px) {
        font-size: 24px;
      }
      @media only screen and (min-width: 800px) and (max-width: 899px) {
        font-size: 30px;
      }
      font-size: 50px;
      font-weight: 600;
      color: #fff;
      line-height: 70px;
      margin-bottom: 26px;
      transition: all 0.3s ease;
    }
    h5 {
      @media only screen and (min-width: 0px) and (max-width: 799px) {
        display: none;
      }
      font-size: 24px;
      line-height: 28px;
      color: #dddfe7;
      font-weight: 500;
    }
  }
  .show {
    @media only screen and (min-width: 800px) and (max-width: 2580px) {
      display: none;
    }
    width: 100%;
  }
  video {
    @media only screen and (min-width: 0px) and (max-width: 799px) {
      display: none;
    }
  }
}
.content {
  box-sizing: border-box;
  padding-top: 80px;
  // 二楼
  .f2 {
    width: 100vw;
    .f2_title {
      display: flex;
      justify-content: center;
      width: 100vw;
      margin-bottom: 50px;
      font-weight: 600;
      color: #34363d;
      user-select: none;
      font-size: 32px;
      @media only screen and (min-width: 0px) and (max-width: 899px) {
        font-size: 24px;
      }
    }
    // 大屏
    .f2_list {
      display: flex;
      justify-content: center;
      .f2_content {
        box-sizing: border-box;
        padding: 0 15px;
        width: 1250px;
        @media only screen and (min-width: 1126px) and (max-width: 1550px) {
          width: 1050px;
        }
        ul {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            position: relative;
            width: 216px;
            height: 210px;
            @media only screen and (min-width: 1126px) and (max-width: 1550px) {
              width: 185px;
              height: 200px;
            }
            @media only screen and (min-width: 800px) and (max-width: 1125px) {
              width: 151.2px;
              height: 150px;
            }
            @media only screen and (min-width: 567px) and (max-width: 799px) {
              width: 105.84px;
              height: 100px;
            }
            @media only screen and (min-width: 400px) and (max-width: 566px) {
              width: 74.088px;
              height: 85px;
            }
            @media only screen and (min-width: 0px) and (max-width: 399px) {
              width: 95px;
              height: 85px;
              margin-bottom: 15px;
            }
            opacity: 1;
            cursor: pointer;
            img {
              z-index: 99;
              height: 95px;
              @media only screen and (min-width: 1126px) and (max-width: 1450px) {
                height: 85px;
              }
              @media only screen and (min-width: 800px) and (max-width: 1125px) {
                height: 66.5px;
              }
              @media only screen and (min-width: 567px) and (max-width: 799px) {
                height: 46.55px;
              }
              @media only screen and (min-width: 0px) and (max-width: 566px) {
                height: 43.218px;
              }
            }
            .animation1,
            .animation2,
            .animation3,
            .animation4,
            .animation5 {
              position: absolute;
              height: 65px;
              @media only screen and (min-width: 1126px) and (max-width: 1450px) {
                height: 45.5px;
              }
              @media only screen and (min-width: 800px) and (max-width: 1125px) {
                height: 31.85px;
              }
              @media only screen and (min-width: 567px) and (max-width: 799px) {
                height: 28px;
              }
              @media only screen and (min-width: 0px) and (max-width: 566px) {
                height: 28px;
              }
            }
            .animation11,
            .animation12,
            .animation13,
            .animation14,
            .animation15 {
              position: absolute;
              height: 44px;
              @media only screen and (min-width: 1126px) and (max-width: 1450px) {
                height: 30.8px;
              }
              @media only screen and (min-width: 800px) and (max-width: 1125px) {
                height: 21.56px;
              }
              @media only screen and (min-width: 567px) and (max-width: 799px) {
                height: 18px;
              }
              @media only screen and (min-width: 0px) and (max-width: 566px) {
                height: 18px;
              }
            }
            .animation21,
            .animation22,
            .animation23,
            .animation24 {
              position: absolute;
              height: 54px;
              @media only screen and (min-width: 1126px) and (max-width: 1450px) {
                height: 37.8px;
              }
              @media only screen and (min-width: 800px) and (max-width: 1125px) {
                height: 26.46px;
              }
              @media only screen and (min-width: 567px) and (max-width: 799px) {
                height: 20px;
              }
              @media only screen and (min-width: 0px) and (max-width: 566px) {
                height: 20px;
              }
            }
            .animation1 {
              left: 25%;
              bottom: 70%;
            }
            .animation2 {
              right: 18%;
              @media only screen and (min-width: 0px) and (max-width: 565px) {
                right: 13%;
              }
              bottom: 50%;
            }
            .animation3 {
              bottom: 100%;
            }
            .animation4 {
              left: 40%;
              bottom: 30%;
            }
            .animation5 {
              right: 15%;
              @media only screen and (min-width: 0px) and (max-width: 565px) {
                right: 10%;
              }
              bottom: 90%;
            }
            .animation5 {
              right: 15%;
              @media only screen and (min-width: 0px) and (max-width: 565px) {
                right: 10%;
              }
              bottom: 90%;
            }
            .animation11 {
              left: 18%;
              top: 31%;
            }
            .animation12 {
              left: 28%;
              @media only screen and (min-width: 0px) and (max-width: 565px) {
                left: 20%;
              }
              top: 10%;
            }
            .animation13 {
              right: 36%;
              top: -5%;
            }
            .animation14 {
              right: 16%;
              top: 10%;
            }
            .animation15 {
              right: 23%;
              top: 33%;
            }
            .animation21 {
              left: 25%;
              @media only screen and (min-width: 0px) and (max-width: 799px) {
                left: 22%;
              }
              bottom: 62%;
            }
            .animation22 {
              left: 52%;
              bottom: 88%;
            }
            .animation23 {
              right: 25%;
              @media only screen and (min-width: 0px) and (max-width: 799px) {
                right: 20%;
              }
              bottom: 79%;
            }
            .animation24 {
              right: 28%;
              @media only screen and (min-width: 0px) and (max-width: 799px) {
                right: 21%;
              }
              bottom: 43%;
            }
            .animation31 {
              position: absolute;
              top: 33px;
              left: 3px;
              width: 197px;
              height: 197px;
              background-color: #f00;
              transform: rotate(300deg);
              // @media only screen and (min-width: 1126px) and (max-width: 1450px) {
              //   width: 137.9px;
              //   height: 97px;
              // }
              // @media only screen and (min-width: 800px) and (max-width: 1125px) {
              //   width: 96.53px;
              //   height: 97px;
              // }
              // @media only screen and (min-width: 567px) and (max-width: 799px) {
              //   width: 67.571px;
              //   height: 97px;
              // }
              // @media only screen and (min-width: 0px) and (max-width: 566px) {
              //   width: 58px;
              //   height: 97px;
              // }
            }
            .animation41 {
              position: absolute;
              top: -29px;
              left: 7px;
              width: 156px;
              height: 134px;
              transform: rotate(300deg);
            }
            span {
              @media only screen and (min-width: 0px) and (max-width: 799px) {
                font-size: 16px;
              }
              font-size: 27px;
              font-weight: 500;
              color: #000000;
            }
          }
        }
      }
    }
    .f2_list ul .li1:hover {
      // background-color: #f00;
      // box-shadow: 8px 0px 20px 0px #6c6c6c;
      // border-radius: 5px;
      .animation1 {
        animation: move-right1 1s linear;
      }
      .animation2 {
        animation: move-right2 1s linear;
      }
      .animation3 {
        animation: move-right3 1s linear;
      }
      .animation4 {
        animation: move-right4 1s linear;
      }
      .animation5 {
        animation: move-right5 1s linear;
      }
    }
    .f2_list ul .li2:hover {
      .animation11 {
        animation: move-right11 1s linear;
      }
      .animation12 {
        animation: move-right12 1s linear;
      }
      .animation13 {
        animation: move-right13 1s linear;
      }
      .animation14 {
        animation: move-right14 1s linear;
      }
      .animation15 {
        animation: move-right15 1s linear;
      }
    }
    .f2_list ul .li3:hover {
      .animation21 {
        animation: move-right21 1s linear;
      }
      .animation22 {
        animation: move-right22 1s linear;
      }
      .animation23 {
        animation: move-right23 1s linear;
      }
      .animation24 {
        animation: move-right24 1s linear;
      }
    }
    .f2_list ul li:hover {
      background-color: #fff;
      box-shadow: 8px 0px 20px 0px #6c6c6c;
      border-radius: 5px;
    }
  }
  // 三楼
  .f3 {
    width: 100vw;
    .f3_title {
      display: flex;
      justify-content: center;
      width: 100vw;
      margin: 160px 0 55px 0;
      @media only screen and (min-width: 0px) and (max-width: 799px) {
        margin: 100px 0 55px 0;
      }
      font-weight: 600;
      color: #34363d;
      user-select: none;
      font-size: 32px;
      @media only screen and (min-width: 0px) and (max-width: 899px) {
        font-size: 24px;
      }
    }
    .f3_ul {
      box-sizing: border-box;
      padding: 0 25px;
      display: flex;
      justify-content: center;
      width: 100vw;
      ul {
        width: 35vw;
        @media only screen and (min-width: 0px) and (max-width: 799px) {
          width: 100vw;
        }
        @media only screen and (min-width: 800px) and (max-width: 999px) {
          width: 80vw;
        }
        @media only screen and (min-width: 1000px) and (max-width: 1319px) {
          width: 70vw;
        }
        @media only screen and (min-width: 1320px) and (max-width: 1535px) {
          width: 60vw;
        }
        display: flex;
        justify-content: space-between;
        li {
          display: flex;
          justify-content: center;
          width: 110px;
          height: 50px;
          font-size: 22px;
          @media only screen and (min-width: 0px) and (max-width: 1250px) {
            font-size: 18px;
            height: 40px;
            width: 90px;
          }
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 500;
          color: #666666;
          cursor: pointer;
          &.active2 {
            color: #219bbb;
            border-bottom: 2px solid #2e59a1;
          }
        }
      }
    }
    .f3_content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 600px;
      @media only screen and (min-width: 0px) and (max-width: 1040px) {
        height: 300px;
      }
      // background-color: #f00;
      background: url(@/assets/home/图层0.png) no-repeat;
      background-size: cover;
      .f3_content_left {
        width: 480px;
        height: 500px;
        margin-top: -39px;
        @media only screen and (min-width: 0px) and (max-width: 1040px) {
          display: none;
        }
        img {
          width: 480px;
          // @media only screen and (min-width: 1041px) and (max-width: 1520px) {
          //   width: 430px;
          // }
          // height: 450px;
        }
      }
      .f3_content_right {
        width: 340px;
        margin-left: 150px;
        @media only screen and (min-width: 0px) and (max-width: 1040px) {
          margin: 0 auto;
        }
        h3 {
          font-size: 24px;
          color: #000;
          font-weight: 500;
          margin-bottom: 10px;
        }
        h5 {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 40px;
          line-height: 26px;
        }
        .f3_content_right_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 140px;
          height: 30px;
          color: #219bbb;
          font-size: 16px;
          border: 1px solid #2e59a1;
          border-radius: 3px;
          cursor: pointer;
        }
      }
      .f3_content_right .f3_content_right_btn:hover {
        background: rgba(4, 30, 80, 0.8);
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
      }
    }
    .f3_ul ul li:hover {
      color: #219bbb;
      // border-bottom: 2px solid #2e59a1;
    }
  }
  // 四楼
  .f4 {
    width: 100vw;
    .f4_title {
      display: flex;
      justify-content: center;
      width: 100vw;
      margin: 160px 0 50px 0;
      font-weight: 600;
      color: #34363d;
      user-select: none;
      font-size: 32px;
      @media only screen and (min-width: 0px) and (max-width: 899px) {
        font-size: 24px;
      }
    }
    .f4_comtent {
      display: flex;
      justify-content: center;
      width: 100vw;
      // @media only screen and (min-width: 899) and (max-width: 1680px) {
      //   width: 20vw;
      // }
      // background-color: #f00;
      // height: 645px;
      img {
        @media only screen and (min-width: 0) and (max-width: 600px) {
          width: 100%;
        }
        // 小屏
        @media only screen and (min-width: 601px) and (max-width: 899px) {
          width: 80%;
        }
        // 小屏
        @media only screen and (min-width: 900px) and (max-width: 1535px) {
          width: 60%;
        }
        // 中屏
        @media only screen and (min-width: 1536px) and (max-width: 1900px) {
          width: 60%;
        }
        // 大屏
        @media only screen and (min-width: 1901px) and (max-width: 2580px) {
          width: 65%;
        }
      }
    }
  }
  // 五楼
  .f5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 250px;
    @media only screen and (min-width: 0) and (max-width: 900px) {
      height: 200px;
    }
    @media only screen and (min-width: 0) and (max-width: 1230px) {
      margin-top: 60px;
    }
    opacity: 1;
    margin-top: 110px;
    background: url(@/assets/home/组39.png) no-repeat;
    background-size: cover;
    .f5_comtent {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
      h3 {
        font-size: 38px;
        font-weight: 600;
        color: #fff;
        user-select: none;
        @media only screen and (min-width: 0) and (max-width: 899px) {
          font-size: 28px;
        }
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 30px;
        font-size: 16px;
        border-radius: 4px;
        color: #184a98;
        font-weight: 500;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
// 动画
@keyframes move-right1 {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 70%;
  }
}
@keyframes move-right2 {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 50%;
  }
}
@keyframes move-right3 {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: 100%;
  }
}
@keyframes move-right4 {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 30%;
  }
}
@keyframes move-right5 {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: 90%;
  }
}
@keyframes move-right11 {
  0% {
    top: 0px;
  }

  100% {
    top: 31%;
  }
}
@keyframes move-right12 {
  0% {
    top: 0px;
  }

  100% {
    top: 10%;
  }
}
@keyframes move-right13 {
  0% {
    top: 0px;
  }

  100% {
    top: -5%;
  }
}
@keyframes move-right14 {
  0% {
    top: 0px;
  }

  100% {
    top: 10%;
  }
}
@keyframes move-right15 {
  0% {
    top: 0px;
  }

  100% {
    top: 33%;
  }
}
// -=------------------------------------
@keyframes move-right21 {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 62%;
  }
}
@keyframes move-right22 {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 88%;
  }
}
@keyframes move-right23 {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: 79%;
  }
}
@keyframes move-right24 {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 43%;
  }
}
</style>
