<template>
  <div>
    <!-- 大屏显示 -->
    <div class="footer_comtent">
      <div class="footer_comtent_top">
        <div class="footer_comtent_top_comtent">
          <div style="width: 390px">
            <img src="../assets/home/logo-底部.png" alt="" />
          </div>
          <ul>
            <li>商务合作</li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4">
              contact@thunlink.com
            </li>
          </ul>
          <ul>
            <li>业务咨询</li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4">
              service@thunlink.com
            </li>
          </ul>
          <ul class="ul_list" style="cursor: pointer">
            <li>关注我们</li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4">商驿供应链公众号</li>
            <div class="erwei">
              <img src="../assets/home/图片1.png" alt="" />
            </div>
            <!-- <li><img src="../assets/home/20230706160236.png" alt="" /></li> -->
          </ul>
          <!-- <ul>
            <li>
              <img
                style="width: 74px; height: 74px"
                src="../assets/home/图片1.png"
                alt=""
              />
            </li>
          </ul> -->
        </div>
      </div>
      <div class="footer_comtent_bottom">
        <div class="footer_comtent_bottom_comtent">
          <ul style="width: 390px">
            <li>联系地址</li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4">
              北京市石景山区泰禾长安中心A座21层
            </li>
          </ul>
          <ul>
            <li>联系电话</li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4">010-53358030</li>
          </ul>
          <ul>
            <li></li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4"></li>
          </ul>
          <ul>
            <li></li>
            <li style="font-family: ArialMT; font-size: 14px; color: #a4a4a4"></li>
          </ul>
        </div>
      </div>
      <!-- 底部信息 -->
      <div class="footer-module_copyright_O0Gf1">
        <a
          style="margin-right: 5px"
          href="http://operate.thunlink.cn/thunlink-wb/隐私政策/隐私政策.html"
          target="_blank"
          >隐私政策</a
        >
        |
        <a
          style="margin: 0 20px 0 5px"
          href="http://operate.thunlink.cn/thunlink-wb/法律声明/法律声明.html"
          target="_blank"
          >法律声明</a
        >版权所有 北京商驿科技有限公司 -
        <a
          style="margin-right: 30px"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >京ICP备2022024109号-2</a
        ><img style="margin-right: 5px" src="../assets/consult/beian.png" alt="" /><a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010702002424"
          target="_blank"
          >京公网安备11010702002424号</a
        >
      </div>
    </div>
    <!-- 小屏显示 -->
    <div class="moreover">
      <div class="center">
        <div class="top_logo">
          <img src="../assets/home/logo-底部.png" alt="" />
        </div>
        <div class="moreover_center">
          <ul>
            <li>商务合作</li>
            <li style="color: #a4a4a4">contact@thunlink.com</li>
          </ul>
          <ul>
            <li>业务咨询</li>
            <li style="color: #a4a4a4">service@thunlink.com</li>
          </ul>
          <ul>
            <li>关注我们</li>
            <li>
              <img style="width: 74px; height: 74px" src="../assets/home/图片1.png" alt="" />
            </li>
            <li style="color: #a4a4a4">商驿供应链公众号</li>
          </ul>
          <ul>
            <li>联系地址</li>
            <li style="font-family: ArialMT; color: #a4a4a4">北京市石景山区泰禾长安中心A座12层</li>
            <li style="font-family: ArialMT; color: #a4a4a4">重庆市渝北区中铁峰汇B座5层</li>
            <li style="font-family: ArialMT; color: #a4a4a4">
              江苏省苏州市吴中区金月恒玺国际B幢5层
            </li>
            <li style="font-family: ArialMT; color: #a4a4a4">
              湖北省武汉市东湖新技术开发区中国光谷科技会展中心2层
            </li>
          </ul>
          <ul>
            <li>联系电话</li>
            <li style="color: #a4a4a4">010-53358030</li>
          </ul>
          <ul>
            <li>
              <a
                style="margin-right: 5px; color: #fff"
                href="http://operate.thunlink.cn/thunlink-wb/隐私政策/隐私政策.html"
                target="_blank"
                >隐私政策</a
              >
              |
              <a
                style="color: #fff"
                href="http://operate.thunlink.cn/thunlink-wb/法律声明/法律声明.html"
                target="_blank"
                >法律声明</a
              >
            </li>
            <li style="color: #a4a4a4">
              <span> 版权所有 北京商驿科技有限公司 </span>
            </li>
            <li>
              <a
                style="margin-right: 30px; color: #a4a4a4"
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >京ICP备2022024109号-2</a
              >
            </li>
            <li>
              <img style="margin-right: 5px" src="../assets/consult/beian.png" alt="" />
              <a
                style="color: #a4a4a4"
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010702002424"
                target="_blank"
                >京公网安备11010702002424号</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer_comtent {
  @media only screen and (min-width: 0px) and (max-width: 1200px) {
    display: none;
  }
  position: relative;
  width: 100vw;
  height: 440px;
  background: #041e50;
  .footer_comtent_top {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 163px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    // 大屏
    @media only screen and (min-width: 0px) and (max-width: 1200px) {
      border: 0px;
      height: 100%;
    }
    .footer_comtent_top_comtent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 66%;
      height: 100%;
      img {
        width: 115px;
        height: 34px;
      }
      ul {
        position: relative;
        width: 155px;
        li {
          font-size: 16px;
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 5px;
        }
        .erwei {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 140px;
          height: 140px;
          box-sizing: border-box;
          position: absolute;
          background-color: #fff;
          top: -140px;
          left: 5px;
          display: none;
          img {
            width: 120px;
            height: 120px;
          }
        }
      }
      .ul_list:hover {
        .erwei {
          display: flex;
        }
      }
    }
  }
  .footer_comtent_bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 49%;
    .footer_comtent_bottom_comtent {
      box-sizing: border-box;
      padding-top: 45px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      width: 66%;
      height: 100%;
      ul {
        width: 155px;
        li {
          font-size: 16px;
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 5px;
        }
      }
    }
  }
  .footer-module_copyright_O0Gf1 {
    position: absolute;
    bottom: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #a4a4a4;
    font-size: 13px;
    a {
      color: #a4a4a4;
    }
  }
  .footer-module_copyright_O0Gf1 a:hover {
    color: #6ebb04;
  }
}
// 小屏显示
.moreover {
  box-sizing: border-box;
  padding: 40px 20px;
  width: 100vw;
  background: #041e50;
  @media only screen and (min-width: 0px) and (max-width: 1199px) {
    display: block;
  }
  @media only screen and (min-width: 1200px) and (max-width: 2580px) {
    display: none;
  }
  .center {
    box-sizing: border-box;
    .top_logo {
      height: 50px;
      margin-bottom: 30px;
      img {
        width: 115px;
        height: 34px;
      }
    }
    .moreover_center {
      ul {
        margin-bottom: 40px;
        li {
          font-size: 15px;
          color: #fff;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
